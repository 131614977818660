<template>
    <div>
        <AdminTop
            heading="Lektioner"
            add-new-url="/admin/events/lessons/new"
        />

        <BaseTable :show-delete="true">
            <TableHeader>
                <TableHeaderCell>
                    Kurs
                </TableHeaderCell>

                <TableHeaderCell>
                    Sal
                </TableHeaderCell>

                <TableHeaderCell>
                    Datum
                </TableHeaderCell>

                <TableHeaderCell>
                    Tid
                </TableHeaderCell>

                <TableHeaderCell>
                    Repeterbar
                </TableHeaderCell>
            </TableHeader>

            <TableBody>
                <TableRow
                    v-for="(lesson, index) in lessons"
                    :key="lesson.id"
                    :to="`/admin/events/lessons/${lesson.id}`"
                    @delete="removeLesson(lesson.id, index)"
                >
                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ lesson.course.name }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ lesson.classroom.name }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ parseISO(lesson.start).toLocaleDateString('sv-SE') }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ parseISO(lesson.start).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) }} - {{ parseISO(lesson.end).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ lesson.repeat_until ? parseISO(lesson.repeat_until).toLocaleDateString('sv-SE') : 'Nej' }}
                        </p>
                    </TableCell>
                </TableRow>
            </TableBody>
        </BaseTable>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseTable from '@/components/table/BaseTable';
import TableHeader from '@/components/table/TableHeader';
import TableHeaderCell from '@/components/table/TableHeaderCell';
import TableBody from '@/components/table/TableBody';
import TableRow from '@/components/table/TableRow';
import TableCell from '@/components/table/TableCell';
import LessonService from '@/services/admin/LessonService';
import parseISO from 'date-fns/parseISO';
import { confirmRemove } from '@/utils/utils.js';

export default {
    components: {
        AdminTop,
        BaseTable,
        TableHeader,
        TableHeaderCell,
        TableBody,
        TableRow,
        TableCell
    },

    data: () => ({
        lessons: []
    }),

    created () {
        this.getLessons();
    },

    methods: {
        async getLessons () {
            const res = await LessonService.all();

            this.lessons = res.data;
        },

        async removeLesson (id, index) {
            if (!confirmRemove()) {
                return;
            }

            const res = await LessonService.remove(id);

            if (res && res.status === 200) {
                const lessons = [...this.lessons];

                lessons.splice(index, 1);

                this.lessons = lessons;
            }
        },

        parseISO
    }
};
</script>

<style lang="postcss" scoped>
</style>
